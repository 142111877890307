import React from "react";
import PropTypes from "prop-types";
import { ActivityIndicator, View } from "react-native";
import { Image } from "@rneui/themed";
import tailwind from "twrnc";
import { DEFAULT_PROFILE_URL } from "../base/Constants";

const EventAttendee = ({ attendee, small }) => {
  return (
    <View style={tailwind`m-1`}>
      <Image
        style={
          small
            ? tailwind`w-[20px] h-[20px] rounded-full m-1`
            : tailwind`w-[40px] h-[40px] rounded-full m-1`
        }
        source={{ uri: attendee.profileUrl || DEFAULT_PROFILE_URL }}
        PlaceholderContent={<ActivityIndicator />}
      />
    </View>
  );
};

EventAttendee.propTypes = {
  attendee: PropTypes.shape({
    profileUrl: PropTypes.string,
  }).isRequired,
  small: PropTypes.bool,
};

export { EventAttendee as default };
