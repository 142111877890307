import React from "react";
import { Platform } from "react-native";
import * as ScreenOrientation from "expo-screen-orientation";
import ApplicationContainer from "./components/base/ApplicationContainer";

export default function App() {
  if (Platform.OS !== "web") {
    ScreenOrientation.unlockAsync();
  }

  return <ApplicationContainer />;
}
