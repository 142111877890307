import PropTypes from "prop-types";

export const TagReducer = (state, item) => {
  let existingItem = null;

  if (item.deleted) {
    state = state.filter((stateItem) => {
      return item.id !== stateItem.id;
    });
  } else {
    existingItem = state.filter((stateItem) => {
      return stateItem.id === item.id;
    })[0];
  }
  if (existingItem) {
    state = state.filter((stateItem) => {
      return stateItem.id !== existingItem.id;
    });
  }
  return [...state, item];
};

TagReducer.propTypes = {
  item: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
};
