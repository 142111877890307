import React, { useRef } from "react";
import PropTypes from "prop-types";
import tailwind from "twrnc";
import { WebScrollContainer } from "../home-screen/WebScrollContainer";
import SearchOnDemandThumbnailStrip from "./SearchOnDemandThumbnailStrip";
import SearchTrainerThumbnailStrip from "./SearchTrainerThumbnailStrip";
import SearchLiveThumbnailStrip from "./SearchLiveThumbnailStrip";

const SearchResultsSectionContainer = ({ item }) => {
  const scrollViewRef = useRef(null);
  const sectionType = item.type.id;
  const isDynamic = item.isDynamic;

  switch (sectionType) {
    case 1: //live
      return (
        <WebScrollContainer
          scrollViewRef={scrollViewRef}
          style={tailwind`pt-1 pb-4`}
        >
          <SearchLiveThumbnailStrip section={item} />
        </WebScrollContainer>
      );
    case 2: //trainers
      return (
        <WebScrollContainer style={tailwind`pb-4`}>
          <SearchTrainerThumbnailStrip section={item} />
        </WebScrollContainer>
      );
    case 6:
    default: //vod
      return (
        <WebScrollContainer
          scrollViewRef={scrollViewRef}
          style={tailwind`pb-4`}
        >
          <SearchOnDemandThumbnailStrip section={item} isDynamic={isDynamic} />
        </WebScrollContainer>
      );
  }
};

SearchResultsSectionContainer.propTypes = {
  item: PropTypes.object.isRequired,
};

export default SearchResultsSectionContainer;
