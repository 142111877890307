import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { View, Platform, Dimensions } from "react-native";
import YTPlayer from "react-native-youtube-iframe";
import tailwind from "twrnc";
import * as ScreenOrientation from "expo-screen-orientation";

const YoutubePlayer = ({ video }) => {
  const [videoId, setVideoId] = useState(video.video.youtubeId);

  const defaultDimensions = {
    height: Dimensions.get("window").height,
    width: Dimensions.get("window").width,
  };
  const defaultOrientation =
    defaultDimensions.width < defaultDimensions.height
      ? "portrait"
      : "landscape";
  const getOrientation = (orientation) => {
    if (
      orientation === ScreenOrientation.Orientation.LANDSCAPE_LEFT ||
      orientation === ScreenOrientation.Orientation.LANDSCAPE_RIGHT
    ) {
      return "landscape";
    }
    return "portrait";
  };
  const [orientation, setOrientation] = useState(defaultOrientation);

  useEffect(() => {
    ScreenOrientation.addOrientationChangeListener(({ orientationInfo }) => {
      setTimeout(() => {
        setOrientation(getOrientation(orientationInfo.orientation));
      }, 300);
    });
  }, [orientation]);

  useEffect(() => {
    setVideoId(video.video.youtubeId);
  }, [video]);

  let playerWidth;
  let playerHeight;
  let containerStyle;
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;

  if (Platform.isPad) {
    return (
      <View>
        <YTPlayer
          height={450}
          play={true}
          videoId={videoId}
          onChangeState={() => {}}
        />
      </View>
    );
  } else if (Platform.isTV) {
    return (
      <View style={tailwind`flex-row`}>
        <YTPlayer
          webViewStyle={tailwind`h-[370px] w-[660px]`}
          play={true}
          controls={true}
          videoId={videoId}
          onChangeState={() => {}}
        />
      </View>
    );
  } else if (Platform.OS === "web") {
    playerHeight = windowHeight - 350;
    playerWidth = playerHeight * 1.3333333;

    containerStyle = tailwind`h-[${windowHeight - 250}px] w-full`;

    return (
      <View style={containerStyle}>
        <YTPlayer
          height={playerHeight}
          width={playerWidth}
          play={true}
          controls={true}
          videoId={videoId}
          onChangeState={() => {}}
        />
      </View>
    );
  } else {
    if (orientation === "portrait") {
      if (windowHeight > windowWidth) {
        //android tablet doesn't set Platform.isPad
        playerHeight = windowHeight / 3 - 50;
      } else {
        playerHeight = 220;
      }
    } else {
      playerHeight = windowHeight - 80;
    }
    playerWidth = playerHeight * 1.8;

    return (
      <View style={tailwind`self-center`}>
        <YTPlayer
          height={playerHeight}
          width={playerWidth}
          play={true}
          videoId={videoId}
          onChangeState={() => {}}
        />
      </View>
    );
  }
};

YoutubePlayer.propTypes = {
  video: PropTypes.object.isRequired,
};

export default YoutubePlayer;
